.plans{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 2rem;
}
.plans .head{
    display: flex;
    gap: 2rem;
    margin-top: -35px;
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
    color: #fff;
    justify-content: center;
}
.plans .plans-cards{
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}
.plans-cards .each-plan{
    display: flex;
    flex-direction: column;
    gap:1.8rem;
    color: #fff;
    background-color: var(--carlorycard);
    padding: 2rem;
    width: 19rem;
    font-family: Arial, Helvetica, sans-serif;
}
/* styling of second plan */
.each-plan:nth-child(2){
    background-image: var(--planCard);
    transform: scale(1.1);
}
.each-plan:nth-child(2)>svg {
    fill: #fff;
}
/* styling of element of each plan */
/* at first the icon */
.each-plan>:nth-child(1){
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
/* secondaly the title */
.each-plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
/* thirdly the price */
.each-plan>:nth-child(3) {
    font-size: 2.5rem;
    font-weight: bold;
}
/* fifthly the span */
.each-plan>:nth-child(5){
    font-size: .8rem;
}

/* styling of features inside the card */
.each-plan .features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.features .feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.features .feature >img{
    width: 1rem;
}
.each-plan:nth-child(2)>button {
    color: orange;
}

.plans-blur-1{
    width: 32rem;
    height: 23rem;
    left: 0rem;
    /* top: 6rem; */
    margin-top: 5rem;
}
.plans-blur-2 {
    width: 32rem;
    height: 23rem;
    right: 0rem;
    /* top: 6rem; */
    margin-top: 9rem;
}

@media screen and (max-width:768px) {
    .plans .head{
        font-size: 1.8rem;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
    .plans .plans-cards{
        flex-direction: column;
        gap: 1rem;
    }
    .plans-cards .each-plan{
        width: 18rem;
    }
.plans .each-plan:nth-child(2){
    transform: none;
} 
    
}

