/* Designing of the Ad div */
.title{
    position: relative;
}
.the-bst-ad , .hello {
    margin-top: 3.5rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 15px 15px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}
.hello{
    /* margin-left: 20rem; */
    position: absolute;
    right: 0;
    top:-4px;
    color: orange;
    text-decoration:underline;
    text-transform: capitalize;
}

.the-bst-ad>span {
    letter-spacing: 1px;
    z-index: 2;
}

.the-bst-ad>div {
    position: absolute;
    left: 10px;
    background-color: var(--orange);
    width: 4.7rem;
    height: 75%;
    border-radius: 3rem;
    z-index: 1;
}

/* Designing of the hero text */
.hero-text{
    display: flex;
    flex-direction: column;
    font-size: 4rem;
    color: #FFF;
    text-transform: uppercase;
    /* gap: 1rem; */
    font-weight: bold;
    text-overflow: inherit;
    margin-top: 1rem;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    letter-spacing: 1px;
    width: 80%;
    text-transform: none;
    font-weight: 250;
    margin-top: 1rem;
}

@media screen and (max-width : 768px) {
    .the-bst-ad{
        margin-top: 0;
        align-self: center;
        font-size: 10.5px;
    }
    .the-bst-ad>div{
        width: 3.8rem;
    }
    .hello{
        position: absolute;
        left:0rem;
        width: 15rem;
        font-size: 12.5px;
    }
    .hero-text{
        margin-top: 4rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
        .hero-text>div:nth-of-type(3){
            font-size: small;
            font-weight: 200;
            letter-spacing: 1px;
            text-align: center;
        }

}