.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.testimonials .left-part{
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-transform: uppercase;
   color: #fff;
}
.left-part >:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-part >:nth-child(2),
.left-part>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.left-part>:nth-child(4){
    text-transform: none;
    letter-spacing: 2px;
    text-align: justify;
    line-height: 40px;

}

.right-part{
    flex: 2;
    position: relative;
    margin-top: -5px;
}
.right-part>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    top: 2rem;
    right: 8rem;
    object-fit: cover;
}
.right-part>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    top:0.9rem;
    right: 9rem;
    background-color: transparent;
    border: 2px solid orange;
}
.right-part>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}
.right-part .arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: -1rem;
    left: 2rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .testimonials{
        flex-direction: column;
    }
.left-part>:nth-child(2),
.left-part>:nth-child(3) {
    font-size: xx-large;
}
.testimonials .right-part{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.right-part >div{
    position: relative;
    display: none;
}
.right-part >img{
    position: relative;
    right: 0;
    top: 0;
}
.right-part .arrows{
    left: 6rem;
    bottom: 1rem;
}
}