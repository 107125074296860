.btn-RS {
    position: absolute;
    right: 3rem;
    top: 3rem;
}
.heart-rate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: 7rem;
    padding: 1rem;
    background-color: var(--darkgray);
    align-items: center;
    border-radius: 5px;
    position: absolute;
    right: 5rem;
    top: 9rem
}

.heart-rate>span:nth-of-type(1) {
    color: var(--gray);
}

.heart-rate>span:nth-of-type(2) {
    color: #fff;
    font-size: 17px;
}



@media screen and (max-width : 768px) {
    .right-hero-side{
        position: relative;
        background: none;
    }
    .heart-rate{
        left: 1rem;
        top: 2rem;
    }
    
}