.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 9rem;
    height: 3rem;
}
.logo:hover{
    cursor: pointer;
}
.header-menu{
    display: flex;
    gap: 2rem;
    list-style: none;
    color: white;
    padding-top: 1rem;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
    transition: all .5s;
}

@media screen and (max-width:768px) {
    .header> :nth-child(2){
        position: fixed;
        right: 1rem;
        z-index: 100;
    }
    .header .header-menu{
        flex-direction: column;
        padding: .8rem;
        background: var(--orange);
        font-size: 14px;
        gap: 1rem;
    }
    .header-menu>li:hover{
        color: var(--darkgray);
    }
    
}
