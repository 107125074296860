.footer{
    position: relative;
}
.footer>hr{
    border: 1px solid var(--lightgray);
}
.footer-imgs{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.socail-img{
    display: flex;
    gap: 4rem;
}
.socail-img>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.img-f>img{
    width: 10rem;
}

.f-blur-1 {
    bottom: 50px;
    right: 15%;
    width: 30rem;
    height: 13rem;
    filter: blur(180px);
    background: red;
}

.f-blur-2 {
    bottom: 50px;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(180px);
    background: rgb(255, 155, 0);
}