.choose{
    display: flex;
    padding: 0 2rem;
    gap: 2rem;
    
}
/* desiging of left side */
.left-side{
    flex: 2;
    display: flex;
    gap: 1rem;
}
.left-side .p1 >img{
    width: 13rem;
    height: 32rem;
}
.left-side .p2{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.left-side .p2 >img{
    width: 28rem;
    height: 19.2rem;
}
.left-side .p2 >div{
    display: flex;
    gap: 1rem;
}
.p2>div>:nth-child(1){
    width: 15rem;
    height: 11rem;
}
.p2>div>:nth-child(2) {
    width: 12rem;
    height: 11rem;
}

/* desgining of right side */
.right-side {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.right-side >span{
    font-weight: bold;
    color: var(--orange);
    font-size: 18px;
    margin-top: 2rem;
    text-decoration: underline;
}
.right-side .title{
    color: #fff;
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
}
.right-side .details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details >div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
    align-items: center;
    font-size: 18px;
    font-family:monospace;
    color: #fff;
    text-transform: uppercase;
 
}
.details >div>img{
    width:2rem;
    height: 2rem;
}

.partners{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.partners>span{
    color: var(--orange);
    font-size: 19;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}
.partners .tradeMarks{
    display: flex;
    gap: 2rem;

}
.tradeMarks>img{
    width: 2.5rem;
}

@media screen and (max-width : 768px) {
    .choose{
        flex-direction: column;
    }
    .choose .left-side{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-side .p1>img{
        width: 7rem;
        height: 18rem;
    }
    .left-side .p2>img{
        width: 15rem;
        height: 10rem;
    }
    .left-side .p2>div>:nth-child(1){
            width: 8rem;
            height: 6.5rem;
        }
    .left-side .p2>div>:nth-child(2){
        width: 7rem;
        height: 6rem;
    }
    .right-side{
        margin-top: -30px;
    }
    .right-side .title{
        font-size: 2.5rem;
    }
}