.figures{
    display: flex;
    gap: 3rem;
}
/* designing of divs and sapns */
.figures>div{
    display: flex;
    flex-direction: column;
    color: #fff;
}
.figures>div>span:nth-of-type(1){
    font-size: 1.6rem;
}
.figures>div>span:nth-of-type(2) {
    color: var(--gray);
    font-size: 14px;
    text-transform: uppercase;
}
/* designing of the buttons */
.hero-btns{
    display: flex;
    gap:1rem;
    font-weight: no;
}
.hero-btns>:nth-child(1){
    background-color: var(--orange);
    width: 8rem;
    color: #fff;
}
.hero-btns>:nth-child(2){
    color: #fff;
    width: 8rem;
    background-color: transparent;
    border: 2px solid var(--orange);
}

@media screen and (max-width : 768px) {
    .figures>div>span:nth-child(1){
        font-size: large;
    }
    .figures>div>span:nth-child(2) {
        font-size: 11px;
    }
    .hero-btns{
        justify-content: center;
    }
}