.hero{
    display: flex;
    justify-content: space-between;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}
.left-hero{
    flex:3.2;
    padding: 2rem;
    padding-top: 1rem;
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
}
.right-hero{
    flex:.8;
    background-color: var(--orange);
    position: relative;
}

.hero-image{
    position: absolute;
    right: 10rem;
    top:11rem;
    width: 19rem;
}
.hero-back{
    position: absolute;
    top:3rem;
    right: 22rem;
    width: 16rem;
    z-index: -1;
}
.calories{
    position: absolute;
    top:27rem;
    left: 33rem;
    display: flex;
    padding: 1rem;
    gap:1.5rem;
    width: fit-content;
    border-radius: 5px;
    background-color: var(--carlorycard);
}
.calories>img{
    width: 2.5rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color: var(--gray);
    font-size: 16px;
}
.calories>div>:nth-child(2){
    color: #FFF;
    font-size: 18px;
}

.hero-blur{
    width: 25rem;
    height: 30rem;
    left: 0;
}

@media screen and (max-width : 768px) {
    .hero{
        display: flex;
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .hero-image{
        position: relative;
        width: 15rem;
        left: 4rem;
        top: 6rem;
        align-self: center;

    }
    .hero-back{
        width: 15rem;
        left: 2rem;
        top: 35rem;
    }
    .calories{
        position: relative;

    }
   
}