.join{
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
    margin-top: 1.5rem;
}
.left-join{
    color: #fff;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.left-join>span{
    width: 10.5rem;
    height: 4px;
    border:2px solid var(--orange);
    border-radius: 20%;
}

.right-join{
    
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-form{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-form>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-join{
    background-color: var(--orange);
    color: #fff;
}

@media screen and (max-width:768px) {
    .join{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: -1rem;
    }
    .join .left-join{
        font-size: xx-large;
    }
    .join .right-join{
        padding: 2rem;
        margin-top: -1rem;
        margin-bottom: -4rem;
    }
    .email-form{
        gap: .5rem;
    }
}